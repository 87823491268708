import Rails from "@rails/ujs";
import "~/src/bootstrap4";
import { loginUserAjax, showLoginModalOn } from "~/src/login";
import {
  modernCollapsable,
  modernDetailLink,
  modernButtonDisable,
  openModernBookModal,
} from "~/src/modern";

if (!window._rails_loaded) {
  Rails.start();
}

loginUserAjax(".modern-login-modal__form");
modernButtonDisable("#new_user", "#sign_in");
modernButtonDisable("#new_user", "#submit_new_pw_instructions");
modernButtonDisable("#new_user", "#submit_confirmation");
modernButtonDisable("#new_user", "#submit_password");
modernButtonDisable("#new_user", "#submit_registration");
modernButtonDisable("#new_user", "#unlock");
modernCollapsable(".show-product-modern__event-header-link");
openModernBookModal(".show-product-modern__book");
modernDetailLink(".modern-tile__details-link");
showLoginModalOn(".show-product-modern__bookmark");
showLoginModalOn(".show-product-modern__rating");
showLoginModalOn(".show-product-modern__book-button");
